<template>
  <v-card elevation="0">
    <v-card-title>{{ $t("ui_teams") }}</v-card-title>
    <v-card-text>
      <v-simple-table>
        <template v-slot:default>
          <thead>
            <tr>
              <th v-for="(th, i) in FIELDS_DISPLAY" :key="i" class="text-left">
                {{ th }}
              </th>
              <th>{{ $t("ui_actions") }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in teams" :key="row.id">
              <td v-for="(td, i) in FIELDS_DISPLAY" :key="i">
                {{ row[td] }}
              </td>
              <td style="min-width: 120px">
                <v-btn icon color="green" x-small @click="openEditModal(row)">
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
                <AdminModalBinding
                  :currentRow="row"
                  :rows="processes"
                  title="Добавить процесс"
                  fieldName="name"
                  @onSave="onBind"
                />
                <v-btn icon color="pink" x-small @click="deleteRow(row.id)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>

    <v-card-actions>
      <v-btn color="accent" elevation="1" small @click="openAddModal">{{
        $t("ui_add")
      }}</v-btn>
    </v-card-actions>

    <AdminModalForm
      ref="AdminModalEditForm"
      :fieldsEdit="FIELDS_EDIT"
      @onSave="onEdit"
    />
    <AdminModalForm
      ref="AdminModalCreateForm"
      :fieldsEdit="FIELDS_CREATE"
      @onSave="onSave"
    />
  </v-card>
</template>

<script>
import api from "@/api";
import AdminModalForm from "@/apps/admin/components/AdminModalForm/AdminModalForm.vue";
import AdminModalBinding from "@/apps/admin/components/AdminModalBinding/AdminModalBinding.vue";

import { mapState, mapActions } from "vuex";

const FIELDS_DISPLAY = ["id", "name", "description"];
const FIELDS_EDIT = ["name", "description"];
const FIELDS_CREATE = ["name", "description"];

export default {
  components: { AdminModalForm, AdminModalBinding },
  data() {
    return {
      FIELDS_DISPLAY,
      FIELDS_EDIT,
      FIELDS_CREATE,
    };
  },
  computed: {
    ...mapState("core$common", ["node", "teams", "processes"]),
  },
  methods: {
    ...mapActions("core$common", ["getTeams"]),
    deleteRow(id) {
      api
        .request({
          name: "deleteTeam",
          pathParam: { nodeId: this.node.id, teamId: id },
          showAlert: true,
        })
        .then(() => this.getTeams());
    },
    onEdit(data) {
      api
        .request({
          name: "editTeam",
          pathParam: { nodeId: this.node.id, teamId: data.id },
          payload: data,
          showAlert: true,
        })
        .then(() => this.getTeams());
    },
    onSave(data) {
      api
        .request({
          name: "createTeam",
          pathParam: this.node.id,
          payload: data,
          showAlert: true,
        })
        .then(() => this.getTeams());
    },
    onBind(data) {
      api
        .request({
          name: "bindTeamToProcess",
          pathParam: this.node.id,
          payload: { process_id: data.row.id, team_id: data.currentRow.id },
          showAlert: true,
        })
        .then(() => this.getTeams());
    },
    openAddModal() {
      this.$refs.AdminModalCreateForm.open({});
    },
    openEditModal(data) {
      this.$refs.AdminModalEditForm.open(data);
    },
  },
};
</script>

<style lang="scss"></style>
